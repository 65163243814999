import React from "react";
import { Col, Row } from "antd";

class ContentJournal extends React.Component {
  render() {
    return (
      <div className="slide-content">
        <Row>
          <Col xs={24} lg={12}>
            <picture>
              <source
                type="image/webp"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-journal-640.webp"
              ></source>
              <source
                type="image/jpg"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-journal-640.jpg"
              ></source>
              <img
                src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-journal-640.jpg"
                className="homepage-image"
                alt="online journal"
                title="online journal"
                width="100%"
                height="100%"
              />
            </picture>
          </Col>
          <Col xs={24} lg={12}>
            <h2>Online Journal</h2>

            <p>
              A great way to remember your days is to keep an online journal.
              DayViewer Journal is a private space for this. It is a simple
              online diary system which lets you write posts and documents.
            </p>
            <p>
              Journal entries could also be drafts for content you create for
              blogging, essays, ideas or other articles. You can choose to share
              individual posts if you so wish to make them public.
            </p>
            <p>
              Journal entries could help learning and studying. Journals can be
              associated together with other entries and other entry types (such
              as Calendar, Notes, Contacts) via labels.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContentJournal;
